import React, { useEffect, useState } from 'react'
import { MapContainer, GeoJSON, TileLayer } from 'react-leaflet'
import * as L from "leaflet";
import "leaflet/dist/leaflet.css"
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import BaseMap from '../components/BaseMap';
import Kenya_boundary from '../assets/data/shapefiles/Kenya_boundary.json';
import Kenya_counties from '../assets/data/shapefiles/Kenya_counties.json';
import Kenya_water_basin from '../assets/data/shapefiles/Kenya_water_basin.json';

import { SelectedFeaturesCroplandStatFunction } from '../helpers/functions';
import OverviewSection from '../components/OverviewSection';
import { useSelectedFeatureContext } from '../contexts/SelectedFeatureContext';
import { BaseMapsLayers, mapCenter, maxBounds, setDragging, setInitialMapZoom } from '../helpers/mapFunction';

import FiltererdJsonFeature from '../components/FiltererdJsonFeature.js';
import SelectedFeatureHeading from '../components/SelectedFeatureHeading.js';
import { useLoaderContext } from '../contexts/LoaderContext.js';


const HomePage = () => {
    const [selectedBasemapLayer, setSelectedBasemapLayer] = useState(BaseMapsLayers[0]);
    const { selectedView, selectedFeatureName, setSelectedFeatureName, dataView } = useSelectedFeatureContext();
    const [landCoverStats, setLandCoverStats] = useState(null);
    const [hydroclimaticStats, setHydroclimaticStats] = useState(null);
    const [croplandHydroclimaticStats, setCroplandHydroclimaticStats] = useState(null);
    const [waterProductivityStats, setWaterProductivityStats] = useState(null);

    const { setIsLoading } = useLoaderContext();


    const handleBasemapSelection = (e) => {
        const selectedItem = BaseMapsLayers.find((item) => item.name === e.target.value);
        setSelectedBasemapLayer(selectedItem);
    };


    const getGeoJsonData = () => {
        switch (selectedView) {
            case 'COUNTY':
                return Kenya_counties;
            case 'BASIN':
                return Kenya_water_basin;
            case 'COUNTRY':
                return Kenya_boundary;
            default:
                return null;
        }
    };


    const fetchCroplandHydroclimaticStats = (view, featureName) => {
        fetch(`/data/${dataView}/CroplandHydroclimaticStats.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const filteredData = featureName === "All"
                    ? data
                    : data.filter(item => item[view] === featureName);
                setCroplandHydroclimaticStats(filteredData);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const fetchWaterProductivityStats = (view, featureName) => {
        fetch(`/data/${dataView}/WaterProductivityStats.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const filteredData = featureName === "All"
                    ? data
                    : data.filter(item => item[view] === featureName);
                setWaterProductivityStats(filteredData);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const fetchHydroclimaticStats = (view, featureName) => {
        fetch(`/data/${dataView}/HydroclimaticStats.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const filteredData = featureName === "All"
                    ? data
                    : data.filter(item => item[view] === featureName);
                setHydroclimaticStats(filteredData);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    const fetchLandCoverStats = (view, featureName) => {
        fetch(`/data/${dataView}/LandcoverStats.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const filteredData = featureName === "All"
                    ? data
                    : data.filter(item => item[view] === featureName);
                setLandCoverStats(filteredData);
            })
            .catch(error => console.error('Error fetching data:', error));
    };




    useEffect(() => {
        if (selectedView && selectedFeatureName) {
            setIsLoading(true);
            Promise.all([
                fetchHydroclimaticStats(selectedView, selectedFeatureName),
                fetchLandCoverStats(selectedView, selectedFeatureName),
                fetchCroplandHydroclimaticStats(selectedView, selectedFeatureName),
                fetchWaterProductivityStats(selectedView, selectedFeatureName)

            ]).then(() => {
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
        }
    }, [selectedView, selectedFeatureName]);



    const SelectedLandCoverStats = landCoverStats && SelectedFeaturesCroplandStatFunction(landCoverStats)





    function DistrictOnEachfeature(feature, layer) {
        // Determine the property name to use based on selectedView

        // Click event handler
        layer.on('click', function (e) {
            setSelectedFeatureName(feature.properties["NAME"]);
        });

        // Mouseover event handler
        layer.on('mouseover', function (e) {
            if (feature.properties && feature.properties["NAME"]) {
                const popupContent = `
                    <div>
                        ${feature.properties["NAME"]}<br/>
                    </div>
                `;
                layer.bindTooltip(popupContent, { sticky: true });
            }
            layer.openTooltip();
        });

        // Mouseout event handler
        layer.on('mouseout', function () {
            layer.closeTooltip();
        });
    }







    return (
        <div className='dasboard_page_container'>
            <div className='main_dashboard'>
                <div className='left_panel_equal'>
                    <SelectedFeatureHeading />





                    {hydroclimaticStats && SelectedLandCoverStats && croplandHydroclimaticStats && waterProductivityStats ? (
                        <>
                            <OverviewSection
                                waterProductivityStats={waterProductivityStats}
                                croplandHydroclimaticStats={croplandHydroclimaticStats}
                                SelectedLandCoverStats={SelectedLandCoverStats}
                                hydroclimaticStats={hydroclimaticStats}
                            />

                        </>




                    ) : (
                        <div className='card_loader_container'>
                            <div className="card_loader">
                                <div className="card_loader_line"></div>
                            </div>
                        </div>

                    )}




                </div>

                <div className='right_panel_equal' >
                    <div className='card_container' style={{ height: "100%" }}>

                        <MapContainer
                            fullscreenControl={true}
                            center={mapCenter}
                            style={{ width: '100%', height: "100%", backgroundColor: 'white', border: 'none', margin: 'auto' }}
                            zoom={setInitialMapZoom()}
                            maxBounds={maxBounds}
                            zoomSnap={0.5}
                            minZoom={setInitialMapZoom() - 1}
                            keyboard={false}
                            dragging={setDragging()}
                            // attributionControl={false}
                            // scrollWheelZoom={false}

                            doubleClickZoom={false}
                        >



                            <div className='map_layer_manager'>
                                <div className="accordion" id="accordionPanelsStayOpenExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                            <button className="accordion-button map_layer_collapse collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                Base Map
                                            </button>
                                        </h2>
                                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
                                            <div className="accordion-body map_layer_collapse_body">
                                                {BaseMapsLayers.map((option, index) => (
                                                    <div key={index} className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id={option.name}
                                                            name="data_type"
                                                            value={option.name}
                                                            checked={selectedBasemapLayer?.name === option.name}
                                                            onChange={handleBasemapSelection}
                                                        />
                                                        <label htmlFor={option.name}>{option.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            {selectedBasemapLayer && selectedBasemapLayer.url && (
                                <TileLayer
                                    key={selectedBasemapLayer.url}
                                    attribution={selectedBasemapLayer.attribution}
                                    url={selectedBasemapLayer.url}
                                    subdomains={selectedBasemapLayer.subdomains}
                                />
                            )}




                            {selectedView && (
                                <GeoJSON
                                    key={selectedView}
                                    style={{ fillColor: '#265073', weight: 2, color: 'black', fillOpacity: "0.3" }}
                                    data={getGeoJsonData().features}
                                    onEachFeature={DistrictOnEachfeature}
                                />
                            )}

                            <FiltererdJsonFeature />






                            <BaseMap />
                        </MapContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage