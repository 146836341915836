import React from 'react'
import pcp_et from "../assets/icons/pcp_et.jpg"
import irrigated_land from "../assets/icons/irrigated_land.jpg"
import evapotranspiration from "../assets/icons/evapotranspiration.jpg"
import precipitation_icon from "../assets/icons/precipitation_icon.jpg"
import green_water from "../assets/icons/green_water.jpg"
import biomass from "../assets/icons/biomass.jpg"
import water_consumption from "../assets/icons/water_consumption.jpg"
import crop_land from "../assets/icons/crop_land.jpg"
import area_icon from "../assets/icons/area_icon.jpg"
import blue_water from "../assets/icons/blue_water.jpg"
import irrigated from "../assets/icons/irrigated.png"
import rainfed from "../assets/icons/rainfed.png"
import { Link } from 'react-router-dom'
import { calculateAverageOfArray, getAnnualMeanDataFromMonthly, getSumAnnualDataFromMonthly, WaterProductivityWeightedMeanStatsFunction } from '../helpers/functions'



const OverviewSection = ({
    SelectedLandCoverStats,
    hydroclimaticStats,
    croplandHydroclimaticStats,
    waterProductivityStats
}) => {


    let totalWeightedAETI = 0;
    let totalWeightedPCP = 0;
    let totalWeightedTBP = 0;
    let totalArea = 0;

    //  Area is in m2
    hydroclimaticStats.forEach(item => {
        totalWeightedAETI += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.AETI)) * 0.001 * item.AREA;
        totalWeightedPCP += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.PCP)) * 0.001 * item.AREA;
        totalWeightedTBP += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.TBP)) * 0.001 * item.AREA;
        totalArea += item.AREA;
    });




    let croplandTotalWeightedAETI = 0;
    let croplandTotalWeightedPCP = 0;
    let croplandTotalWeightedTBP = 0;
    let croplandTotalWeightedETB = 0;
    let croplandTotalArea = 0;

    //  Cropland Area is in ha
     croplandHydroclimaticStats.forEach(item => {
        croplandTotalWeightedAETI += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.AETI)) * 0.001 * item.Cropland_Area * 10000;
        croplandTotalWeightedPCP += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.PCP)) * 0.001 * item.Cropland_Area * 10000;
        croplandTotalWeightedTBP += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.TBP)) * 0.001 * item.Cropland_Area * 10000;
        croplandTotalWeightedETB += calculateAverageOfArray(item.ETB) * 0.001 * item.Cropland_Area * 10000;
        croplandTotalArea += item.Cropland_Area * 10000;
    });
    
    

    const SelectedFeaturesStatsData = waterProductivityStats && WaterProductivityWeightedMeanStatsFunction(waterProductivityStats);

    const croplandPercentage = ((croplandTotalArea * 100) / (totalArea)).toFixed(2)
    const totalIrrigatedLand = SelectedLandCoverStats.Irrigated







    // EvapotranspirationValue in BCM = ET (mm) * 0.001 * Area (m2)/1000000000
    const TotalEvapotranspirationValue = (totalWeightedAETI / 1000000000).toFixed(2)
    const TotalAreaValue = (totalArea * 0.0000001).toFixed(0)
    const TotalIrrigatedLandValue = (totalIrrigatedLand * 0.001).toFixed(2)
    const TotalPrecipitationValue = (totalWeightedPCP / 1000000000).toFixed(2)
    // const TotalWaterConsumption = (totalWeightedAETI / (totalArea * 0.0001)).toFixed(2)
    const TotalPCP_ETValue = ((totalWeightedPCP - totalWeightedAETI) / 1000000000).toFixed(2)
    const TotalBiomassProductionValue = (totalWeightedTBP * 0.0001 / 1000000).toFixed(2)




    const CroplandEvapotranspirationValue = (croplandTotalWeightedAETI / 1000000000).toFixed(2)
    const CroplandAreaValue = (croplandTotalArea * 0.0000001).toFixed(0)
    const CroplandPrecipitationValue = (croplandTotalWeightedPCP / 1000000000).toFixed(2)
    const CroplandWaterConsumption = (croplandTotalWeightedAETI / (totalArea * 0.0001)).toFixed(2)
    const CroplandPCP_ETValue = ((croplandTotalWeightedPCP - croplandTotalWeightedAETI) / 1000000000).toFixed(2)
    const CroplandBiomassProductionValue = (croplandTotalWeightedTBP * 0.0001 / 1000000).toFixed(2)
    const CroplandBlueWaterFootprintValue = (croplandTotalWeightedETB / 1000000000).toFixed(2)
    const CroplandGreenWaterFootprintValue = ((croplandTotalWeightedAETI-croplandTotalWeightedETB) / 1000000000).toFixed(2)
    const GBWP_rainfed=SelectedFeaturesStatsData && calculateAverageOfArray(getAnnualMeanDataFromMonthly(SelectedFeaturesStatsData.TBP_rainfed.map((value, index) => ((value  * 0.1) / SelectedFeaturesStatsData.AETI_rainfed[index])))).toFixed(1)
    const GBWP_irrigated=SelectedFeaturesStatsData && calculateAverageOfArray(getAnnualMeanDataFromMonthly(SelectedFeaturesStatsData.TBP_irrigated.map((value, index) => ((value  * 0.1) / SelectedFeaturesStatsData.AETI_irrigated[index])))).toFixed(1)


    return (
        <>
            <div className='card_container' >
                <div className='card_heading_container'>
                    <div className='card_heading'>
                        <h4>Total area Stats</h4>
                    </div>
                </div>

                <div className='row'>



                    <div className='col-md-2 col-sm-6 col-6 mb-2'>

                        <div className='overview_icons'>
                            <img src={area_icon} alt='icons' />
                        </div>


                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total area</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(TotalAreaValue).toLocaleString()}</span> (1000 ha)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/et">
                            <div className='overview_icons'>
                                <img src={evapotranspiration} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total evapotranspiration (ET)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(TotalEvapotranspirationValue).toLocaleString()}</span> (BCM/year)</p>
                        </div>
                    </div>





                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/pcp">
                            <div className='overview_icons'>
                                <img src={precipitation_icon} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total precipitation(PCP)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(TotalPrecipitationValue).toLocaleString()}</span> (BCM/year)</p>
                        </div>
                    </div>


                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/pcp">
                            <div className='overview_icons'>
                                <img src={pcp_et} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total water yield (PCP-ET)</h5>
                            <p>
                                {TotalPCP_ETValue < 0 ? (
                                    <>
                                        <span style={{ fontSize: "24px", color: "red" }}>{TotalPCP_ETValue}</span> (BCM/year)
                                    </>


                                ) : (
                                    <>
                                        <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{TotalPCP_ETValue}</span> (BCM/year)
                                    </>

                                )}
                            </p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <div className='overview_icons'>
                            <Link to="/bp">
                                <img src={biomass} alt='icons' />
                            </Link>
                        </div>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total biomass production (TBP)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{TotalBiomassProductionValue}</span> (million tons/year)</p>
                        </div>
                    </div>







                </div>
            </div>

            <div className='card_container'>
            <div className='card_heading_container'>
                    <div className='card_heading'>
                        <h4>Cropland area Stats</h4>
                    </div>
                </div>

                <div className='row'>


                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/lcc">
                            <div className='overview_icons'>
                                <img src={crop_land} alt='icons' />
                            </div>
                        </Link>


                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total cropped land</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(CroplandAreaValue).toLocaleString()}</span> (1000 ha) <br />
                                <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {croplandPercentage}</span> (%)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/lcc">
                            <div className='overview_icons'>
                                <img src={irrigated_land} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total irrigated land</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(TotalIrrigatedLandValue).toLocaleString()}</span>  (1000 ha)</p>
                        </div>
                    </div>


                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/et">
                            <div className='overview_icons'>
                                <img src={evapotranspiration} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland evapotranspiration (ET)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(CroplandEvapotranspirationValue).toLocaleString()}</span> (BCM/year)</p>
                        </div>
                    </div>





                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/pcp">
                            <div className='overview_icons'>
                                <img src={precipitation_icon} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland precipitation(PCP)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(CroplandPrecipitationValue).toLocaleString()}</span> (BCM/year)</p>
                        </div>
                    </div>



                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/pcp">
                            <div className='overview_icons'>
                                <img src={pcp_et} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland Water yield (PCP-ET)</h5>
                            <p>
                                {CroplandPCP_ETValue < 0 ? (
                                    <>
                                        <span style={{ fontSize: "24px", color: "red" }}>{CroplandPCP_ETValue}</span> (BCM/year)
                                    </>


                                ) : (
                                    <>
                                        <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{CroplandPCP_ETValue}</span> (BCM/year)
                                    </>

                                )}
                            </p>
                        </div>
                    </div>

                    

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <div className='overview_icons'>
                            <Link to="/bp">
                                <img src={biomass} alt='icons' />
                            </Link>
                        </div>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland biomass production (TBP)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{CroplandBiomassProductionValue}</span> (million tons/year)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <div className='overview_icons'>
                            <Link to="/wp">
                                <img src={irrigated} alt='icons' />
                            </Link>
                        </div>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Gross Biomass Water Productivity in irrigated area (GBWP<sub>irrigated</sub>)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{GBWP_irrigated}</span> (kg/m³)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <div className='overview_icons'>
                            <Link to="/wp">
                                <img src={rainfed} alt='icons' />
                            </Link>
                        </div>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Gross Biomass Water Productivity in rainfed area (GBWP<sub>rainfed</sub>)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{GBWP_rainfed}</span> (kg/m³)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/wf">
                            <div className='overview_icons'>
                                <img src={blue_water} alt='icons' />
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland blue ET</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{CroplandBlueWaterFootprintValue}</span> (BCM/year)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/wf">
                            <div className='overview_icons'>
                                <img src={green_water} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland green ET</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{CroplandGreenWaterFootprintValue}</span> (BCM/year)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>

                        <div className='overview_icons'>
                            <img src={water_consumption} alt='icons' />
                        </div>


                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland Unit water consumption</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(CroplandWaterConsumption).toLocaleString()}</span> (m³/ha)</p>
                        </div>
                    </div>





                </div>
            </div>

        </>

    )
}

export default OverviewSection